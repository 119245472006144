.headline-project-cmg-modal {
  padding: 0 80px;
  display: flex;

  &-data-view {
    width: 505px;
    margin-right: 24px;
    padding-top: 28px;

    .card {
      padding: 0;
      border: none;
      background: none;
      box-shadow: none;
      border-radius: 20px;
      // border-image: linear-gradient(180deg, rgba(96, 141, 212, 1), rgba(51, 117, 189, 0.4), rgba(0, 90, 163, 1)) 1 1;
      backdrop-filter: blur(6px);
      margin-bottom: 24px;
    }

    .card-content {
      padding-top: 0;
    }
  }

  &-data-group {
    display: flex;
    border: 1px solid #547CBA;
    height: 160px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    border-radius: 20px;

    padding: 0 !important;
  }

  &-overview {
    display: flex;
    align-items: center;
    margin-bottom: 76px;

    &-count {
      font-size: 44px;
      font-family: Oxanium;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 53px;
      background: linear-gradient(360deg, #6DC6FF 0%, #FFFFFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      span {
        font-size: 24px;
        font-family: HarmonyOS_Sans;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-type {
      font-size: 32px;
      color: #FFFFFF;
      line-height: 32px;
      text-shadow: 0px 1px 6px rgba(0, 147, 255, 0.7);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    img {
      width: 122px;
      height: 148px;
      margin-right: 13px;
    }
  }

  &-content {
    flex: 1;
    padding: 16px 0 16px 40px;
    box-sizing: border-box;
    width: 740px;
    height: 596px;

    overflow-y: auto;

    .card-content {
      padding: 0 30px 0 0;

      &>div {
        padding: 0;
      }
    }

    &-item {
      color: #FFF;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 20px !important;

      &-title {
        width: auto;
        display: inline-block !important;
        background-color: #FFF;
        padding: 0 20px 0 6px;
        margin-bottom: 12px;

        background: linear-gradient(270deg, rgba(0, 128, 255, 0.1) 0%, rgba(0, 128, 255, 0.5) 100%);
        border-radius: 100px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;

        span {
          display: inline-block;
          width: 30px;
          height: 30px;
          background: linear-gradient(144.09deg, #67CCFF -39.6%, #003BB1 131.33%);
          border-radius: 100px;
          text-align: center;
          line-height: 30px;

          margin-right: 8px;
        }
      }

      &-content {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        width: 590px;
        /* or 167% */

        text-align: justify;
        color: #D9E7FF;

        margin-bottom: 14px;
      }
    }
  }

  &-theme {
    flex: 1;
    padding: 16px 0 16px 40px;
    box-sizing: border-box;
    width: 740px;
    height: 596px;

    overflow-y: auto;

    .card-content {
      padding: 0 30px 0 0;

      &>div {
        padding: 0;
      }
    }

    &-extra {
      display: flex;
      margin: 0 0 12px 0;
    }

    &-share {
      margin-right: 44px;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;

      img {
        width: 18px !important;
        height: 18px !important;
        margin-right: 11px !important;
      }
    }

    &-item {
      color: #FFF;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 20px !important;

      &-title {
        width: auto;
        display: inline-block !important;
        padding: 0 20px 0 6px;
        margin-bottom: 12px;
        border-radius: 100px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;

        div {
          display: inline-block;
          text-align: center;
          line-height: 30px;

          background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/headline-project-theme-icon.png) no-repeat;
          background-size: 100% 100%;
          padding: 4px 12px;

          margin-right: 16px;
        }
      }

      &-content {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        width: 610px;
        word-break: break-all;
        /* or 167% */

        text-align: justify;

        color: #D9E7FF;

        margin-bottom: 14px;

        & >div {
          display: flex;
          margin-bottom: 20px;

          img {
            flex: 0 0 auto;
            width: 24px;
            height: 24px;
            margin: 8px 4px 0 0;
          }
        }

        p {
          margin: 0 0;
        }
      }
    }
  }

  &-typography {
    padding: 20px 0;
  }

  &-tooltip {
    transform: translate(10px, 0);
  }
}