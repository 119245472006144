.headline-project-other-media-modal {
  display: flex;
  justify-content: space-around;
  padding: 0 50px;

  &-card {
    flex: 1;
    margin: 0 15px;
    // padding: 40px;
    overflow-y: hidden;
    height: 606px;

    .card-title {
      padding: 40px 40px 0 40px;
    }

    .typography-list-item-content {
      flex: 1;
      width: 540px !important;
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 32px;
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      line-height: 32px;
      text-shadow: 0px 1px 6px rgba(0, 147, 255, 0.7);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;

      width: 441px;
      height: 79px;
      box-sizing: border-box;

      padding: 0 7px 7px 7px;

      background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/headline-other-item-bg.png') no-repeat top;
      background-size: 100% 100%;

      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }

    .card-content {
      height: 450px;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
        width: 8px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: rgba(0,139,255,0.4);
        border-radius: 4px;
      }
  
      &>div {
        padding: 0 40px !important;
      }
    }
  }

  &-typography {
    width: 540px;
    padding: 20px 0;
  }
}