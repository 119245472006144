.image-hover {
  // width: 300px;
  // height: 200px;
  // overflow: hidden;

  .image {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
  }

  .image.hovered {
    transform: translateY(-10%);
  }
}