.scrolling-number {
  width: 88px;
  height: 88px;
  box-sizing: border-box;

  overflow: hidden;

  &-container {
    width: 62px;
    height: 60px;
  }

  &-warp {
    // --duration: 0.2s;
    // --translate: -60px;
    // --delay: 1s;

    animation-name: up;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: var(--duration);
    animation-fill-mode: both;
    animation-delay: var(--delay);

    @keyframes up {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(var(--translate));
      }
    }
  }

  &-item {
    width: 88px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 46px;

    font-family: Oxanium;
    font-weight: 700;
    font-size: 72px;

    background: linear-gradient(180deg, #FFFFFF 0%, #5FE3FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}