@font-face {
  font-family: YouSheBiaoTiHei;
  src: url('./fonts/YouSheBiaoTiHei.ttf');
  font-weight: 400;
}

@font-face {
  font-family: HarmonyOS_Sans;
  src: url('./fonts/HarmonyOS_Sans_Bold.ttf');
  font-weight: 400;
}

@font-face {
  font-family: HarmonyOS_Sans_Light;
  src: url('./fonts/HarmonyOS_Sans_Light.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Oxanium;
  src: url('./fonts/Oxanium-VariableFont_wght.ttf');
  font-weight: 400;
}

@keyframes effect-icon-top-in {
  0% {
    opacity: 0;
    margin-top: -50px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

body,
html {
  width: 100vw;
  overflow-x: hidden;
}

.app {
  width: 100vw;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: fixed;
  background-size: 100%;
  min-height: 100vh;
  color: #fff;
  font-family: HarmonyOS_Sans;

  video {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-warp {
    width: 100vw;
    min-height: 100vh;
    background: #060a16 url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-bg-animation.png') no-repeat fixed;
    background-size: 100%;
  }

  &-container {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 103px);
  }

  &-line {
    position: absolute;
    z-index: 4;
    width: 370px;
    height: 109px;
    top: 416px;
    left: 50%;
    margin-left: -190px;
  }

  &-element {
    position: absolute;
    z-index: 2;
    width: 420px;
    top: 139px;
    left: 50%;
    margin-left: -210px;
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
  }

  &-type-btn {
    position: absolute;
    width: 242px;
    height: 357px;
    top: 0;
    left: 0;
    z-index: 6;

    opacity: 0;

    animation: fade-in 1s ease-in forwards;

    .image-hover {
      margin-top: 20px;
    }

    &>img {
      width: 242px;
      position: absolute;
      left: 2px;
      bottom: 5px;
      z-index: -1;
    }

    &-hover-warp {
      width: 242px;
      height: 357px;
      z-index: 3;
    }

    &-header {
      background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/index-type-btn-title-bg.webp) no-repeat top;
      background-size: 100% 100%;
      backdrop-filter: blur(6px);
      width: 175px;
      height: 47px;

      margin: 0 auto;

      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      font-size: 27px;

      text-align: center;
      line-height: 47px;

    }

    &-container {
      width: 242px;
      height: 333px;
      // margin-top: -23.5px;
      text-align: center;

      img {
        width: 129px;
        animation: breathe 2s ease-in-out infinite;

        @keyframes breathe {
          0% {
            transform: translateY(0);
          }

          50% {
            transform: translateY(-5px);
          }

          100% {
            transform: translateY(0);
          }
        }
      }
    }

    &-1 {
      top: 429px;
      left: 223px;
      animation-delay: 0.8s;
    }

    &-2 {
      top: 547.5px;
      left: 614px;
      animation-delay: 0.2s;
    }

    &-3 {
      top: 547.5px;
      left: 1114px;
      animation-delay: 0.2s;
    }

    &-4 {
      top: 148px;
      left: 380px;
      animation-delay: 1s;
    }

    &-5 {
      top: 148px;
      left: 1294px;
      animation-delay: 1s;
    }

    &-6 {
      top: 429px;
      left: 1441px;
      animation-delay: 0.8s;
    }
  }

  &-key-report {
    .app-type-btn-container {
      img {
        width: 115px;
      }
    }

    .image-hover {
      margin-top: -40px;
    }
  }

  &-headline-project {
    .app-type-btn-container {
      img {
        width: 153px;
        margin-top: 20px;
      }
    }

    .image-hover {
      margin-top: -40px;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.ant-message {
  top: 300px !important;
}

.app-message {

  .ant-message-notice-content {
    background-color: #060a16;
    backdrop-filter: blur(6px);
    color: #fff;
  }
}

.element-top-in {
  opacity: 0;
  animation: element-top-in 0.9s ease-in forwards;

  @keyframes element-top-in {
    0% {
      opacity: 0;
      top: -100px;
      margin-top: -100px;
    }

    100% {
      opacity: 1;
      top: 100px;
      margin-top: 0;
    }
  }
}

.element-right-in {
  opacity: 0;
  animation: element-right-in 0.9s ease-in-out forwards;

  @keyframes element-right-in {
    0% {
      opacity: 0.3;
      right: -100px;
      margin-left: 100px;
    }

    100% {
      opacity: 1;
      right: 0;
      margin-left: 0;
    }
  }
}

.modal-close {
  position: fixed;
  width: 48px;
  height: 48px;
  top: 94px;
  right: calc(50vw - 700px - 72px);
  cursor: pointer;
  z-index: 2000;
}

.ant-modal-mask {
  background: linear-gradient(rgba(12, 16, 27, 0.75) 0%, rgba(10, 15, 28, 0.85) 100%) !important;
  animation: mask-fade-in 0.2s ease-in forwards;
  backdrop-filter: blur(6px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  @keyframes mask-fade-in {
    0% {
      z-index: 0;
      opacity: 0;
    }

    90% {
      z-index: 2;
      opacity: 0.65;
    }

    100% {
      z-index: 998;
      opacity: 1;
    }
  }
}

.ant-popover-inner,
.ant-popover-arrow::before {
  background-color: #003469 !important;
  // border: 1px solid #547CBA;
}

.ant-popover-inner {
  border: 1px solid #547CBA;
}

.ant-popover-arrow::before {
  z-index: 999;
}

.ant-popover-arrow::after {
  height: 16px;
  border-right: 1px solid #547CBA;
  border-bottom: 1px solid #547CBA;
  z-index: 999;
}

.ant-popover-content {
  // backdrop-filter: blur(6px);
}

.ant-popover-inner-content {
  font-weight: 400;
  color: #D9E7FF !important;
  line-height: 26px;
  max-width: 240px;
}

.g2-tooltip {
  background: #01152F !important;
  box-shadow: inset 0px 0px 24px rgba(47, 141, 255, 0.6) !important;

  .g2-tooltip-list-item {
    color: #fff !important;
    font-family: Oxanium;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;

    text-align: center;

    color: #FFFFFF;
  }
}

@media (prefers-reduced-motion: no-preference) {
  // .App-logo {
  //   animation: App-logo-spin infinite 20s linear;
  // }
}