.other-media-program-preview-model {
  margin: 0 59px;
  height: 600px;
  overflow: hidden;

  .tabs-items {
    background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-tab-bg.png) no-repeat;
    background-size: 100% 100%;
    width: 1272px;
    height: 400px;

    animation: element-top-in 0.9s ease-in forwards;

    &>div {
      padding: 20px 0;
    }
  }

  .carousel {
    padding: 0 100px;
    width: 1272px;
    height: 400px;
    box-sizing: border-box;

    .control {
      width: 60px;
      height: 60px;

      img {
        width: 10px;
      }
    }

    .prev {
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(calc(-50% - 20px)) rotate(180deg);
    }

    .next {
      top: calc(100% + 10px);
      left: 50%;
      transform: translateY(0);
      transform: translateX(calc(50% + 10px));
    }
  }

  &-tab {
    padding: 0 !important;

    .tabs-labels {
      padding: 0 40px 20px 40px;
      margin-bottom: 10px;
      border: none;
    }
    
    .tabs-items {
      padding: 20px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 278.18px;
    height: 200px;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(3.60378px);
    /* Note: backdrop-filter has minimal browser support */

    margin-top: 80px;

    border-radius: 9.0909px;

    color: #fff;

    text-align: center;

    font-family: 'PingFang HK';
    font-style: normal;
    font-weight: 400;
    line-height: 36px;

    transition: all 0.5s ease-in;

    img {
      // width: 151.25px;
      height: 44px;
      margin-bottom: 10px;
      transition: all 0.5s ease-in;
    }

    div {
      font-size: 23.6363px;
      transition: all 0.5s ease-in;
      line-height: 1.2;
    }

    &-active {
      width: 500px;
      height: 340px;
      margin-top: 0;

      img {
        height: 78px;
        margin-bottom: 45px;
      }

      div {
        font-size: 42.5455px;
        margin-bottom: 20px;
      }
    }
  }
}