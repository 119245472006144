.other-media-news-topics-model {
  display: flex;
  padding: 0 15px;
  justify-content: center;

  &-card {
    width: 620px;
    height: 596px;
    margin: 0 15px;
    &-title {
      display: flex;
      align-items: center;
    }

    &-source-icon {
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;

      background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-news-title-icon-bg.png') no-repeat top;
      background-size: 100% 100%;
      img {
        width: 92px;
        height: 92px;
        margin: auto;
      }
    }

    &-source {
      background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-news-title-source-bg.png') no-repeat top;
      background-size: 100% 100%;

      width: 388px;
      height: 64px;

      font-size: 32px;
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      line-height: 64px;

      padding-left: 24px;
      // text-shadow: 0px 1px 6px rgba(0,147,255,0.7);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    .card-content {
      padding: 0 0 0 10px !important;
      height: 450px;
    }

    .typography-list {
      padding: 0 40px;
    }
  }

  &-typography {
    margin-bottom: 30px;
    width: 494px;
  }
}