.key-reports-key-theme-list-modal {
  padding: 0 20px 0 58px;

  margin: -20px 14px 0 14px;

  overflow-y: auto;
  overflow-x: hidden;

  &-card:hover {
    background: #0080FF15;
  }

  &>div {
    width: calc(100% / 2 - 24px);
    height: 102px;
    margin: 12px 0;

    position: relative;

    padding: 0 12px;

    float: left;
  }

  &-card {
    position: absolute;
    background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/key-theme-bg-image.png) no-repeat top;
    left: 0;
    background-size: 100% 100%;
    height: 102px;
    width: calc(100% - 24px);
    color: #FFFFFF;

    border-radius: 10px;

    padding: 12px 24px !important;


    opacity: 0;
    animation: element-top-in 0.9s ease-in forwards;

    @keyframes element-top-in {
      0% {
        opacity: 0;
        margin-right: -100px;
      }

      100% {
        opacity: 1;
        margin-right: 24px;
      }
    }
  }

  &-card-title {
    display: flex;
    align-items: center;
    font-size: 32px;
    font-family: HarmonyOS_Sans;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 38px;
    height: 78px;
    cursor: pointer;

    &-content {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 473px;
      flex: 1;
    }

    .badge {
      // float: right;
      margin-right: 10px;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 139, 255, 0.4);
    border-radius: 4px;
  }
}