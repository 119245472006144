.date-picker {
  background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/datepicker-bg.png);
  background-size: 100% 100%;
  width: 1032px;
  height: 718px;

  &-header {
    height: 70px;
    display: flex;
    padding-top: 8px;
    justify-content: center;
    // background: linear-gradient(360deg, rgba(39, 147, 255, 0.3) 0%, rgba(39, 147, 255, 0.22) 19%, rgba(39, 147, 255, 0.05) 100%) linear-gradient(180deg, rgba(33, 144, 255, 0.3) 0%, rgba(39, 147, 255, 0) 100%) linear-gradient(180deg, rgba(18, 178, 255, 0.7) 0%, rgba(39, 147, 255, 0) 100%);
    // border-radius: 4px 4px 0px 0px;
    // border: 4px solid;
    // // border-image: radial-gradient(circle, rgba(255, 255, 255, 1), rgba(39, 115, 255, 0)) 4 4;
    // backdrop-filter: blur(20px);

    &-group {
      display: flex;
      align-items: center;
    }

    &-arrow {
      cursor: pointer;
      width: 34px;
      height: 34px;
      margin: 0 9.5px;
    }

    &-date {
      font-size: 32px;
      font-family: Helvetica;
      color: #FFFFFF;
      line-height: 38px;
      background: linear-gradient(180deg, #FFFFFF 0%, #90DEFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 9.5px;
    }

    &-arrow-rotate {
      transform: rotateY(180deg)
    }
  }

  &-container {
    height: 530px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    margin-top: -8px;
    margin-bottom: 15px;
    padding: 15px 30px 0 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #D9E7FF;
  }

  &-footer {
    display: flex;
    height: 100px;
    padding: 0 86px;

    &-input {
      width: 280px;
      height: 52px;
      padding: 7px 19px;
      box-sizing: border-box;
      // background-color: linear-gradient(180deg, rgba(0, 14, 28, 0.45) 0%, rgba(0, 31, 62, 0.35) 100%) linear-gradient(180deg, rgba(39, 147, 255, 0.1) 1%, rgba(39, 147, 255, 0) 100%) linear-gradient(180deg, rgba(39, 147, 255, 0.2) 0%, rgba(39, 147, 255, 0) 100%) linear-gradient(180deg, rgba(39, 147, 255, 0.5) 0%, rgba(39, 147, 255, 0) 100%);
      background: linear-gradient(180deg, rgba(0, 14, 28, 0.45) 0%, rgba(0, 31, 62, 0.35) 100%);
      border-radius: 3px;
      border: 2px solid;
      border-image: linear-gradient(360deg, rgba(39, 147, 255, 0.5), rgba(39, 147, 255, 0.63)) 2 2;
      backdrop-filter: blur(20px);

      font-size: 24px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #D9E7FF;
      line-height: 35px;
      margin-right: 30px;
    }

    &-current {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 52px;

      font-size: 24px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #80BFFF;
      line-height: 32px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }

    &-acitons {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      button {
        width: 122px;
        height: 51px;
        background: linear-gradient(180deg, #1575D4 0%, #175EEC 100%);
        border-radius: 3px;
        border: none;

        font-size: 24px;
        font-family: HarmonyOS_Sans;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
      }
    }
  }

  &-week-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc((100%) / 7 - 10px);
    box-sizing: border-box;
    text-align: center;
    padding: 15px;
    font-size: 28px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #D9E7FF;
    line-height: 40px;
    opacity: 0.8;
  }

  &-date {
    cursor: pointer;
    width: 44px !important;
    height: 44px!important;
    margin: 0 42px;
    display: flex;
    border-radius: 4.5px;
    justify-content: center;
    align-items: center;
    width: calc((100%) / 7 - 10px);
    box-sizing: border-box;
    text-align: center;
    padding: 15px;
    font-size: 28px;
    font-family: HarmonyOS_Sans_Light;
    line-height: 40px;
    opacity: 0.8;
  }

  &-date:hover {
    background: linear-gradient(180deg, rgba(38, 147, 255, 0.25) 0%, rgba(14, 95, 255, 0.25) 100%);
  }

  &-current-month {
    font-family: HarmonyOS_Sans !important;
    color: #FFFFFF;
    font-weight: 500;
    opacity: 1;
  }

  &-selected {
    background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/datepicker-selected.png) no-repeat top;
    // background-color: #1575D4;
    background-size: 100% 100%;
    font-weight: 500 !important;
  }

  &-today {
    background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/date-picker-today.png) no-repeat top;
    // background-color: #1575D4;
    background-size: 100% 100%;
    font-weight: 500 !important;
  }
}