.outline-daily-points-modal {
  margin: 0 55px;
  overflow: hidden;

  &-list {
    width: 100%;
    display: flex;
  }

  &-item {
    width: 1124px;
    margin: 0 20px;
  }

  &-overview {
    display: flex;
    margin-bottom: 44px;
    justify-content: center;
  }

  &-image {
    width: 568px;
    height: 321px;
    // background: #01152F;
    box-shadow: 0px 9px 66px 0px #0A0F1C, inset 0px 0px 26px 0px rgba(102, 179, 255, 0.26);
    border: 3px solid #0A3969;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    img {
      height: 321px;
      margin-left: -30px;
    }

    div {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 50px);
      padding: 16px 25px;

      background: rgba(0, 0, 0, .6);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      z-index: 3;
      font-size: 22px;
      font-family: HarmonyOS_Sans;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 36px;
      text-shadow: 0px 1px 6px rgba(0, 147, 255, 0.7);
      word-break: break-all;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  &-data-view {
    width: 550px;
    // height: 320px;
    padding-left: 32px;

    &-warp {
      width: 550px;
      height: 324px;
      // padding-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #042b63;
      border-bottom: 1px solid #042b63;
    }

    &-border {
      background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-tab-bg.png) no-repeat;
      background-size: cover;
      width: 100%;
      height: 1px;
    }
  }

  &-content {
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    height: 120px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 139, 255, 0.4);
      border-radius: 4px;
    }
  }

  &-todes-list {
    width: 100%;
    display: flex;
    align-items: center;
    height: 562px;
  }

  &-todes-content {
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    max-height: 562px;
    overflow-y: auto;
    text-align: justify;
    text-align-last: left;
    padding-right: 32px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 139, 255, 0.4);
      border-radius: 4px;
    }
  }
}