.other-media-international-media-model {
  padding: 0 40px;

  &-container {
    position: relative;
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/world-map.png') no-repeat center;
    background-size: 100% 100%;
    width: 1224px;
    height: 622.35px;
    margin-right: auto;
    margin-left: auto;


    opacity: 0;
    animation: media-model-animate 0.9s ease-in forwards;

    @keyframes media-model-animate {
      0% {
        opacity: 0;
        margin-top: -100px;
      }

      100% {
        opacity: 1;
        margin-top: 0;
      }
    }
  }
}