.outline-key-programs-modal {
  &-container {
    display: flex;
    padding: 0 61px;
    justify-content: space-between;
  }

  &-text {
    opacity: 0;
    width: 1140px;
    text-align: center;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    padding-top: 56px;

    &-animation {
      opacity: 0;
      animation: element-top-in 0.5s ease-in forwards;

      @keyframes element-top-in {
        0% {
          opacity: 0;
          margin-top: 0;
        }

        100% {
          opacity: 1;
          margin-top: 156px;
        }
      }
    }
  }
}