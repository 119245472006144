.page-carousel {

  &-conatiner {
    position: relative;
  }

  &-action-group {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    margin-top: 20px;

    img {
      width: 36px;
      height: 36px;
      cursor: pointer;
    }

    div {
      margin: 0 10px;
    }

    .prev {
      transform: rotate(-180deg);
    }

  }

  &-page-number {
    font-weight: 400;
    color: #D9E7FF;
    line-height: 40px;
  }

  .slider {
    position: absolute;
    display: flex;
    justify-content: space-between;
  }

  .slide {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }

  .control {
    position: absolute;
    width: 56px;
    top: 50%;
    margin-top: -60px;
    transform: translateY(-50%);
    border: none;
    font-size: 0.24rem;
    cursor: pointer;
  }
}