.outline-daily-view {
  flex: 0 0 auto;
  text-align: center;
  padding: 18px 0 28px 10px;
  box-sizing: border-box;
  display: flex;
  height: 158.5px;

  // align-items: center;
  justify-content: center;

  &-container {
    width: 100%;
  }

  &-amount {
    font-size: 36px;

    font-family: 'Oxanium';
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 120%;
    /* or 62px */


    background: linear-gradient(0deg, #FFD5A6 22.76%, #FFF3F2 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    span {
      color: #FFFFFF;
    }
  }

  &-warp {
    display: flex;
    justify-content: center;
  }

  &-unit {
    color: #FFFFFF;
    font-size: 22px;
    line-height: 30px;
    margin-left: 5px;
    padding-top: 20px;
  }

  &-content {
    font-size: 20px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #D9E7FF;
    line-height: 30px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &-dot-warp {
    margin-left: 20px;
    flex: 0 0 auto;
    display: flex;
    height: 100%;
    width: 1px;
    align-items: center;
    padding-top: 10px;
  }

  &-dot {
    width: 1px;
    height: 97px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  &-fullrow {
    width: 600px;
  }

  &-border {
    border-bottom: 1px solid #042b63;
  }
}