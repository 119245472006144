.data-overview {
  position: absolute;
  background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/app-date-overview-bg.png') no-repeat top;
  background-size: 100% 100%;
  top: 10px;
  height: 380px;
  left: 50%;
  padding: 14px;
  z-index: 9;
  opacity: 0;
  width: 0;

  &-fade-in {
    animation: expand 0.6s ease-out forwards;
    animation-delay: 1.3s;

    @keyframes expand {
      from {
        opacity: 0;
        width: 0;
        margin-left: 0;
        transform: translateX(-50%);
      }

      to {
        width: 668px;
        opacity: 1;
        margin-left: -348px;
        transform: translateX(0);
      }
    }
  }

  &-nomal {
    width: 668px;
    opacity: 1;
    margin-left: -348px;
    transition: top 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.5s ease-in-out, margin-left 0.2s ease-in-out, transform 0.2s ease-in-out;
    ;
  }

  &-common:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.05);
  }

  &-modal {
    top: 200px;
    width: 668px;
    opacity: 1;
    margin-left: -348px;
    transform: scale(1.8);
    z-index: 999;
  }

  &-closing {
    width: 668px;
    opacity: 0;
    margin-left: -348px;
    z-index: -1;
  }

  &-number {
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/app-overview-number-bg.png') no-repeat top;
    background-size: 100% 100%;
    width: 88px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 5px;
    padding: 7px 0;

    span {
      // background-image: linear-gradient(360deg, rgba(105, 231, 255, 1) 0, rgba(202, 233, 255, 1) 100%);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 46px;
      letter-spacing: 3.299999952316284px;
      font-family: Helvetica-Bold;
      font-weight: 700;
      white-space: nowrap;
      line-height: 56px;
      -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  &-numbers {
    margin-top: 30px;
    height: px;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
    animation-delay: 1.5s;
  }

  &-title {
    margin-top: 13px;
    padding-bottom: 20px;
    font-family: 'PingFang HK';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
    animation-delay: 1.5s;
  }

  &-line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0 40px;
    height: 1px;

    &>div {
      border-bottom: 1px solid #5FE3FF;
      margin-top: 1px;
      float: left;
      display: block;
      width: 6px;
    }

    .after {
      float: right !important;
    }
  }

  &-data {
    margin: 0 10px;
    padding: 20px 22px 15px 22px;
    display: flex;
    text-align: center;
    justify-content: space-around;
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
    animation-delay: 1.2s;

    &-item {
      padding: 0 14px;

      span {
        font-size: 18px;
        font-family: HarmonyOS_Sans;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 33px;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }

    .count {
      font-size: 26px;
      font-family: 'Oxanium';
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      /* identical to box height, or 58px */


      background: linear-gradient(0deg, #5FE3FF, #5FE3FF), linear-gradient(180deg, #FFFFFF 0%, #5FE3FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .type {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.2;
    }
  }

  &-entrances {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 55px;
    cursor: pointer;
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
    animation-delay: 0.8s;
  }

  &-entrance {
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/app-overview-btn.webp') no-repeat top;
    background-size: 100% 100%;
    font-size: 22px;
    font-family: HarmonyOS_Sans;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 26px;
    width: 142px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    // background: linear-gradient(180deg, #FFFFFF 0%, #B5E9FF 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
}