.international-communication-overivew-modal {
  display: flex;
  padding: 112px 80px;

  &-card {
    width: 940px;
    height: 297px;
    padding: 39px 30px;
    box-sizing: border-box;
  
    .card-content {
      padding-top: 0;
      overflow: hidden;
    }
  }

  &-overview {
    width: 220px;
    margin-right: 90px;

    img {
      width: 122px;
      height: 148px;
      margin-bottom: 10px;
    }

    &-count {
      width: 240px;
      font-size: 48px;
      font-family: Oxanium;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 58px;
      background: linear-gradient(360deg, #6DC6FF 0%, #FFFFFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      span {
        font-size: 24px;
        font-family: HarmonyOS_Sans;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-title {
      font-size: 28px;
      font-family: PingFang HK;
      color: #FFFFFF;
      line-height: 32px;
      width: 240px;
      // text-shadow: 0px 1px 6px rgba(0,147,255,0.7);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  &-data-list {
    padding: 0 10px !important;
    width: 828px;
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  &-data-item {
    display: flex;
    align-items: center;
    width: 275px;
    height: 93px;
    margin-bottom: 30px;

    img {
      width: 66px;
      height: 66px;
      margin-right: 15px;
    }
  }

  &-type {
    font-size: 18px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #D9E7FF;
    line-height: 24px;
    margin-bottom: 7px;
  }

  &-count {
    font-size: 32px;
    font-family: Oxanium;
    font-weight: bolder;
    color: #FFFFFF;
    line-height: 38px;

    span {
      font-size: 20px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }
}