.international-communication-theme-modal {
  padding: 0 55px;

  &-typography {
    padding: 20px 0;
  }

  &-card {
    padding: 16px 0;
    width: 1272px;
    height: 596px;
  }

  .card-content {
    padding-top: 0;

    &>div {
      padding: 0 40px !important;
    }
  }
}