.international-communication-center-modal {
  padding: 0 55px;
  &-card {
    padding: 20px 0;

    width: 1272px;
    height: 596px;
    box-sizing: border-box;

    .card-content {
      overflow-y: hidden;
      padding-top: 0;
      &>div {
        padding: 0;
      }
    }

    .tabs-labels {
      padding: 0 40px 20px 40px;
    }
    
    .tabs-items {
      padding: 15px 40px;
    }
  }

  &-typography {
    padding: 20px 0;
  }
  .typography-list {
    width: 100%;
  }
}