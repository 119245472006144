.effect-hot-broadcasting-cgtn-modal {
  width: 1272px;
  padding: 0 60px;

  &-data {
    &-overview {
      display: flex;
      align-items: center;
      margin-bottom: 56px;

      img {
        width: 123px;
        height: 141px;
        margin-right: 13px;
        opacity: 0;
        animation: effect-icon-top-in 0.9s ease-in forwards;
      }

      &-count {
        font-size: 56px;
        font-family: Oxanium;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 60px;
        background: linear-gradient(360deg, #6DC6FF 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        span {
          font-size: 24px;
          font-family: HarmonyOS_Sans;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &-title {
        font-size: 28px;
        font-family: PingFang;
        color: #FFFFFF;
        line-height: 40px;
        // text-shadow: 0px 1px 6px rgba(0,147,255,0.7);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      display: flex;
      align-items: center;
      width: 272px;
      margin: 0 80px 56px 0;

      img {
        width: 66px;
        height: 66px;
        margin-right: 15px;
      }

      &-name {
        font-size: 18px;
        font-family: HarmonyOS_Sans;
        font-weight: 400;
        color: #D9E7FF;
        line-height: 24px;
        margin-bottom: 6px;
      }

      &-count {
        font-size: 32px;
        font-family: Oxanium;
        font-weight: Bold;
        color: #FFFFFF;
        line-height: 38px;
      }
    }

    &-extra {
      position: absolute;
      top: -60px;
      right: 62px;
      opacity: 0.5;
      font-size: 16px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: rgba(217, 231, 255, 0.6);
      line-height: 20px;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  &-card {
    height: 243px;
    box-sizing: border-box;
    border-radius: 20px;
    backdrop-filter: blur(6px);
    padding-bottom: 40px;

    .card-content {
      padding-top: 0;
      max-height: 100%;

      text-align: justify;
      text-align-last: left;

      &>div {
        padding: 40px 40px 0 40px;
      }
    }

    opacity: 0;
    animation: element-top-in 0.9s ease-in forwards;

    @keyframes element-top-in {
      0% {
        opacity: 0;
        margin-top: -100px;
      }

      100% {
        opacity: 1;
        margin-top: 0;
      }
    }
  }

  &-intro {
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;

    span {
      color: rgba(217, 231, 255, 0.3);
    }
  }
}