.key-reports-key-theme-modal {
  overflow-x: hidden;
  height: 645px;
  margin: 0 26px 0 16px;

  &-content {
    padding-bottom: 32px;
    margin: 0 40px 32px 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-family: 'PingFang';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.6;
    color: #fff;
    width: 1274px;
  }

  &-container {
    // display: flex;
    // flex-wrap: wrap;
    -moz-column-count: 2;
    /* Firefox */
    -webkit-column-count: 2;
    /* Safari �� Chrome */
    column-count: 2;
    /* 将 div 元素中的文本分为2列，并规定列间1rem像素的间隔。 */
    -moz-column-gap: 10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    margin-right: -15px;
    padding: 0 31px 0 31px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 139, 255, 0.4);
    border-radius: 4px;
  }

  // &>div {
  //   padding: 0 40px !important;
  // }

  &-card {
    -moz-page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    width: 624px;
    margin-bottom: 30px;
    border-radius: 12px;
    padding: 25px 23px;
    background: linear-gradient(270deg, rgba(1, 78, 154, 0) 0%, rgba(1, 78, 154, 0.15) 100%);
    backdrop-filter: blur(13.5914px);

    .card-content {
      padding-top: 20px;
      color: rgba(217, 231, 255, 0.8);

      text-align: justify;
      text-align-last: left;
    }
  }

  &-card-title {
    display: flex;
    // align-items: center;

    img {
      width: 64px;
      height: 64px;
      margin-right: 16px;
      border-radius: 50%;
    }
  }

  &-card-header {
    font-size: 32px;
    font-family: HarmonyOS_Sans;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 64px;
  }

  &-card-extra {
    width: 414px;
    padding-top: 13px;
    font-family: HarmonyOS_Sans;
    font-size: 18px;
    line-height: 27px;
    color: #D9E7FF;
    opacity: .8;
    width: 100%;
  }

  &-card-warp {
    flex: 1;
  }

  .tabs-labels {
    padding: 0 32px;
    border-bottom: none;
  }
}