.page-header {
  width: 100vw;
  // background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/page-header-background-image.webp') no-repeat fixed;
  background-size: 100%;
  height: 103px;
  display: flex;
  font-size: 20px;
  font-family: PingFang-SC;
  opacity: 0;
  animation: fade-in 1s ease-in forwards;
  animation-delay: 1s;

  video {
    position: absolute;
    width: 100%;
    height: 103px;
  }

  .issue {
    padding: 48px;
    width: 500px;
    font-size: 20px;
  }

  .title {
    width: 840px;
    text-align: center;
    padding-top: 25px;
    z-index: 2;
    img {
      width: 440px;
    }
  }

  .extra {
    width: 500px;
    text-align: right;
    padding: 37px 39px 0 48px;
    z-index: 2;

    .date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 20px;
      font-family: HarmonyOS_Sans;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 28px;
      text-shadow: 0px 2px 10px rgba(43, 172, 255, 0.6);
      margin-bottom: 8px;
      
      span {
        margin: 0 8px;
        cursor: pointer;
      }

      .disabled {
        opacity: .2;
      }

      .left {
        transform: rotate(180deg);
        margin-right: 3px;
      }
    }

    .date-range {
      font-size: 14px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #D9E7FF;
      line-height: 16px;
      opacity: 0.6;
    }
  }
}