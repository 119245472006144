.carousel {
  position: relative;
  padding: 0 96px 0 98px;
  margin: 0 auto;
  width: 1304px;

  &-conatiner {
    position: relative;
    width: 100%;
    height: 360px;
    overflow: hidden;
  }

  .slider {
    position: absolute;
    display: flex;
  }

  .slide {
    flex-shrink: 0;
    cursor: pointer;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    flex: 0 0 auto;
    padding: 0 12px;
  }

  .control {
    position: absolute;
    width: 56px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    font-size: 0.24rem;
    cursor: pointer;
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}