.modal-card {
  color: #fff;

  .ant-modal-content,
  .ant-modal-header {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }

  &-container {
    height: 630px;
    overflow-y: auto;
    // font-family: YouSheBiaoTiHei;
    // width: 1158px;
    // height: 446px;
    // margin: 0 auto;
    // border-radius: 20px;
    // padding: 20px 40px;

    // background: rgba(10, 116, 255, 0);
    // box-shadow: inset 0px 0px 100px 0px rgba(0, 18, 35, 0.5);
    // border-radius: 20px;
    // border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(96, 141, 212, 1), rgba(51, 117, 189, 0.4), rgba(0, 90, 163, 1)) 1 1;
    // backdrop-filter: blur(6px);
    // clip-path: inset(0 round 20px);
  }
  // 边框问题
}