.outline-key-programs-data-view {
  margin-top: 100px;
  transition: margin-top 0.5s ease-in-out;
  &-active {
    margin-top: 5px !important;
    &>div {
      width: 350px;
      height: 350px;
    }

    img {
      width: 360px !important; 
      height: 360px !important; 
      margin-left: -4px !important;
    }
  }

  &-img {
    width: 151.58px;
    height: 151.58px;
    background: no-repeat top;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    border-radius: 50%;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  }

  img {
    width: 160px;
    height: 160px;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    margin-left: -3.71px;
  }

  &-content {
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    padding: 0 24px;
    text-align: left;
    word-break: break-all;
  }
}