.news-item {
  position: absolute;
  width: 300px;
  left: 176px;
  bottom: 301px;
  color: #FFFFFF;

  &-continent {
    cursor: pointer;
    // width: 91px;
    height: 36px;
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/world-map-item.png') no-repeat center;
    background-size: 100% 100%;
    padding: 0 12px;
    position: absolute;
    transform: translate(calc(-50% + 8px), 0);
    bottom: 40px;
    left: 0;

    font-size: 21px;
    font-family: YouSheBiaoTiHei;
    line-height: 36px;
    text-align: center;

    span {
      margin-left: 8px;
      font-family: 'YouSheBiaoTiHei';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      /* identical to box height */

      text-align: center;
      background: linear-gradient(0deg, #FFD5A6 22.76%, #FFFFFF 77.56%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }


  // &-fade-in {
  //   animation: news-fade-in 0.6s ease-out forwards;
  //   animation-delay: 1.3s;

  //   @keyframes news-fade-in {
  //     from {
  //       opacity: 0;
  //       width: 0;
  //       margin-left: 0;
  //       transform: translateX(-50%);
  //     }

  //     to {
  //       width: 467px;
  //       opacity: 1;
  //       margin-left: -334px;
  //       transform: translateX(0);
  //     }
  //   }
  // }

  &-news {
    opacity: 0;
    font-size: 26px;
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/world-map-news-item.png') no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    margin-left: -233.5px;
    bottom: -10px;
    left: 0;
    backdrop-filter: blur(20px);
    z-index: 99999;
    /* Note: backdrop-filter has minimal browser support */


    animation: news-fade-in 0.6s ease-out forwards;
    animation-delay: 0.1s;

    @keyframes news-fade-in {
      from {
        opacity: 0;
        width: 0;
        margin-left: 0;
        transform: translateX(-50%);
      }

      to {
        width: 467px;
        opacity: 1;
        margin-left: -233.5px;
        transform: translateX(0);
      }
    }

    &-continent {
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      line-height: 34px;
      padding-left: 12px;
      background: transparent;
      border: 1px solid #40B6F1;
      opacity: 0;
      animation: continent-fade-in 0.6s ease-out forwards;
      animation-delay: 0.7s;

      span {
        margin-left: -8px;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 38px;
        /* identical to box height */

        text-align: center;

      }

      img {
        float: right;
        margin-right: 0;
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
    }

    &-content {
      padding: 16px 12px;
      word-break: break-all;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 28px;
      font-size: 18px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      /* 这里是超出几行省略 */
      overflow: hidden;
      border-left: 1px solid #40B6F1;
      border-right: 1px solid #40B6F1;
      border-bottom: 1px solid #40B6F1;
      backdrop-filter: blur(10px);

      opacity: 0;
      animation: continent-fade-in 0.2s ease-out forwards;
      animation-delay: 1s;
    }


    @keyframes continent-fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  &-arrow {
    bottom: 0;
    width: 30px;
    height: 37px;
    z-index: 100000;
  }

  &-animate {
    position: absolute;
    top: -28px;
    left: -46px;
    z-index: -1;
  }
}