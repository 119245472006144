.comment-cmg-modal {
  margin: 0 28px 0 16px;
  overflow-x: auto;
  height: 595px;

  &-container {
    padding: 0 44px;
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 139, 255, 0.4);
    border-radius: 4px;
  }

  &>div {
    padding: 0 60px;
  }


  &-card {
    -moz-page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    width: 100%;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 25px 23px;
  }

  &-card-title {
    display: flex;
    // align-items: center;

    img {
      width: 64px;
      height: 64px;
      margin-right: 19px;
      border-radius: 50%;
    }
  }

  &-card-header {
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }

  &-card-extra {
    padding-top: 13px;
    font-family: HarmonyOS_Sans;
    font-size: 18px;
    line-height: 27px;
    color: #D9E7FF;
    opacity: .8;
  }
}