.tabs {
  &-labels {
    padding-bottom: 20px;
    height: 60px;
    display: flex;
    overflow-x: auto;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    &>div {
      cursor: pointer;
    }
  }

  &-label {
    text-align: center;
    line-height: 56px;
    height: 56px;
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 500;
    color: #D9E7FF;
    display: flex;
    align-items: center;
    border-radius: 28px;
    padding: 0 20px;
    margin-right: 16px;

    opacity: .5;
    border: 1px solid transparent;
    // border-image: linear-gradient(180deg, rgba(96, 141, 212, 1), rgba(51, 117, 189, 0.4), rgba(0, 90, 163, 1)) 1 1;
    // backdrop-filter: blur(6px);

    img {
      margin-right: 6px;
    }
  }

  &-label:hover {
    border: 1px solid #1489D4;
    backdrop-filter: blur(27.1828px);
  }

  &-active-label {
    height: 56px;
    background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/tabs-active-bg.png) no-repeat top;
    background-size: cover;
    margin-right: 16px;
    border-radius: 28px;
    border: 1px solid #1489D4;
    display: flex;
    align-items: center;

    padding: 0 20px;

    line-height: 56px;
    // text-align: center;
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 500;
    color: #FFFFFF;

    img {
      margin-right: 6px;
    }
  }

  &-items {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 139, 255, 0.4);
      border-radius: 4px;
    }
  }
}