.outline-flagship-platform-item {
  width: 413.33;
  height: 280px;
  background: linear-gradient(180deg, rgba(11, 110, 210, 0.1) 0%, rgba(13, 117, 221, 0.3) 100%);
  border-radius: 10px;
  backdrop-filter: blur(2.916666666666667px);
  padding: 22px 20px;
  margin: 0 12px 15px 12px;
  box-sizing: border-box;

  &-title {
    font-size: 26px;
    font-family: PingFang HK;
    color: #FFFFFF;
    line-height: 40px;
    text-shadow: 0px 1px 6px rgba(0, 147, 255, 0.7);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  &-count {
    font-family: 'Oxanium';
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 55px;
    margin-bottom: 30px;
    /* identical to box height */


    background: linear-gradient(0deg, #A6FFEF 22.76%, #FFFFFF 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    span {
      font-size: 18px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }
  }

  &-count-1 {
    background: linear-gradient(0deg, #A6FFEF 22.76%, #FFFFFF 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &-count-2 {
    background: linear-gradient(0deg, #84D5FE 22.76%, #FFFFFF 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &-count-3 {
    background: linear-gradient(0deg, #85A1FF 22.76%, #FFFFFF 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &-count-4 {
    background: linear-gradient(0deg, #F6C0FF 22.76%, #FFFFFF 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &-count-5 {
    background: linear-gradient(0deg, #FFA6A7 22.76%, #FFFFFF 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &-count-6 {
    background: linear-gradient(0deg, #FFD5A6 22.76%, #FFFFFF 77.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &-chart {
    width: 365.33px;
    height: 110px;
  }

  &-content {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
  }
}