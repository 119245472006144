.typography-list {
  &-shadow {
    box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.1);
  }

  &-item {
    // height: 80px;
    display: flex;
    // align-items: center;

    &-bulleted {
      width: 24px;
      height: 24px;
      padding-top: 8px;
      margin-right: 8px;
    }

    &-number {
      width: 34px;
      height: 34px;
      flex: 0 0 auto;
      font-size: 22px;
      text-align: center;
      font-family: Helvetica;
      color: #FFFFFF;
      line-height: 34px;
      margin: 4px 8px 0 0;
      text-shadow: 0px 0px 7px rgba(25, 223, 242, 0.5);
      background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/number-icon.png') no-repeat top;
      background-size: 100% 100%;
    }

    &-content {
      font-size: 24px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      text-align: justify;
      text-align-last: left;
    }
  }
}