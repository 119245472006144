.modal-group {
  position: relative;

  &-container {
    position: relative;
    height: 565px
  }

  &-warp {
    height: 565px;
  }

  &-gradual-change {
    position: absolute;
    width: 100%;
    height: 110px;
    background: linear-gradient(180deg, rgba(12, 16, 27, 0.75) 0%, rgba(10, 15, 28, 0.85) 100%);
    bottom: 0;
    left: 0;
  }

  &-header {
    font-family: YouSheBiaoTiHei;
    height: 125px;
    text-align: center;
    padding-top: 10px;
    font-size: 44px;
    color: #fff;
    line-height: 93px;
    font-weight: normal;
    background: linear-gradient(180deg, #FFFFFF 0%, #90DEFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-preview {
    position: fixed;
    left: 0;
    top: 908px;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    height: 110px;
    color: #fff;
    z-index: 10000;
    cursor: pointer;

    animation: preview-slide-in 0.9s ease-in forwards;

    @keyframes preview-slide-in {
      0% {
        opacity: 0.5;
        top: 768px;
      }

      100% {
        opacity: 1;
        top: 908px;
      }
    }

    &-item {
      width: 180px;
      height: 88px;
      background-color: linear-gradient(180deg, rgba(54, 141, 227, 0) 0%, rgba(54, 141, 227, 0.1) 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      background-size: 100% 100%;
      border: 2px solid;
      margin: 0 15px;
      opacity: 0.4;

      img {
        width: 100%;
      }
    }

    &-item:hover {
      background: rgba(16, 136, 255, 0.05);
      border: 2px solid #5BADFF;
      background-size: 100% 100%;
      margin: 0 15px;
    }

    &-active-item {
      width: 180px;
      height: 88px;
      background: linear-gradient(180deg, rgba(54, 141, 227, 0) 0%, rgba(54, 141, 227, 0.1) 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 2px 16px 0px rgba(68, 161, 255, 0.65);
      background-size: 100% 100%;
      border-radius: 8px;
      border: 2px solid;
      margin: 0 15px;
      cursor: pointer;

      img {
        width: 100%;
      }

      // border-image: linear-gradient(157deg, rgba(200, 228, 255, 1), rgba(54, 140, 227, 1)) 2 2;
      // backdrop-filter: blur(10px);
    }

  }

  &-close {
    position: fixed;
    width: 56px;
    height: 56px;
    top: 100px;
    right: 56px;
    cursor: pointer;
    z-index: 10000;
  }

  &-modal {
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/modal-background-image.webp') no-repeat top;
    background-size: 100% 100%;
    min-width: 1400px;
    height: 800px;
    opacity: 0;
    width: 520px;
    backdrop-filter: blur(6px);
    animation: modal-slide-in 0.9s ease-in forwards;

    @keyframes modal-slide-in {
      0% {
        opacity: 0;
        top: -100px;
      }

      100% {
        opacity: 1;
        top: 94px;
      }
    }

    .ant-modal-content,
    .ant-modal-header {
      background: transparent;
      box-shadow: none;
      padding: 0;
    }

    .ant-modal-body {
      margin-top: -10px;
    }
  }

  &-modal-detail {
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/modal-detail-background-image.png') no-repeat top !important;
    background-size: 100% 100% !important;

    &-header {
      height: 44px;
      font-size: 44px;
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      line-height: 44px;
      font-weight: normal;
      padding: 15px;
      display: flex;
      align-items: center;
      margin: 12px 12px 36px 12px !important;

      margin-bottom: 44px;

      img {
        width: 37px;
        height: 37px;
        margin-right: 22px;
        cursor: pointer;
      }

      .hover {
        display: none;
      }

    }

    &-header:hover {
      .common {
        display: none;
      }

      .hover {
        display: block;
      }
    }
  }
}