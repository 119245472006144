.effect-hot-broadcasting-CCTV-modal {
  display: inline-flex;
  padding: 0 60px;
  box-sizing: border-box;
  width: 100%;

  &-data {
    width: 330px;
    margin: 0 108px 0 40px;
    position: relative;

    &-warp {
      // overflow: hidden;
      // margin-top: -240px;
      // width: 100%;
      // height: 60px;

      opacity: 0;
      animation: cctv-modal-warp-top-in 0.9s ease-in forwards;

      @keyframes cctv-modal-warp-top-in {
        0% {
          opacity: 0;
          margin-top: 90px;
        }
        100% {
          opacity: 1;
          margin-top: 120px;
        }
      }
    }


    &-chart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      margin: 10px 0 20px -20px;

      &>div {
        width: 200px;
        height: 160px;
        margin-bottom: 20px;
        transform: rotate(-90deg);
      }

      &-progress {
        margin-bottom: 20px;
        opacity: .3;
        position: relative;
        cursor: pointer;

        &:hover {
          .effect-hot-broadcasting-CCTV-modal-data-chart-progress-tooltip {
            display: block !important;
          }
        }

        &-tooltip {
          background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/line-chart-tooltip-bg.png) no-repeat fixed;
          background-size: 100% 100%;
          position: absolute;
          border-radius: 4px;
          color: #fff;
          top: -19px;
          left: 95px;
          width: 97px;
          height: 56px;
          transform: rotate(90deg);
          padding-top: 8px;
          display: none;

          .value {
            font-family: 'Oxanium';
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 24px;
            color: #5FE3FF;
            text-align: center;
          }

          .key {
            text-align: center;
          }
        }

        &-active {
          opacity: 1 !important;
        }

        .ant-progress-bg {
          height: 12px !important;
        }
      }

      .ant-progress-line {
        width: 130px;
      }
    }

    &-title {
      font-family: YouSheBiaoTiHei;
      color: #FFFFFF;
      font-size: 32px;
      line-height: 98px;
      text-shadow: 0px 1px 6px rgba(0, 147, 255, 0.7);
      display: inline-flex;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;

      &>div {
        width: 236px;
      }
    }

    &-number {
      font-size: 48px;
      font-family: Oxanium;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 48px;
      text-align: center;
      margin-bottom: 11px;
      margin-top: -90px;

      span {
        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #FFFFFF;
        margin-left: 5px;
        line-height: 29px;
      }
    }

    &-contrast {
      font-size: 18px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #D9E7FF;
      line-height: 20px;
      opacity: 0.6;
      margin-bottom: 54px;

      display: flex;
      align-items: center;
      justify-content: center;

      div {
        font-weight: 600;
        color: #fff;
        line-height: 20px;
        opacity: 1;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent
      }
    }

    &-trend {
      width: 34px !important;
      height: 40px !important;
    }

    &-rate-up {
      font-family: Oxanium;
      margin: -10px;
      color: #D2FFF3 !important;
      text-shadow: 0px 0px 12px #00AF83;
    }

    &-rate-down {
      font-family: Oxanium;
      margin: -10px;
      color: #FFBBBB !important;
      text-shadow: 0px 0px 12px #FF0031;
    }

    &-extra {
      position: absolute;
      bottom: 18px;
      font-size: 16px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: rgba(217, 231, 255, 0.6);
      line-height: 20px;
      opacity: 0.5;
    }

    img {
      width: 86px;
      height: 98px;
    }
  }

  &-card {
    width: 798px;
    height: 596px;
    position: relative;
    box-sizing: border-box;
    border-radius: 20px;
    backdrop-filter: blur(6px);

    .card-container {
      padding: 10px 40px 10px 40px;
    }

    .card-content {
      margin: 20px 12px;
      padding-top: 0;
      height: 550px;
      box-sizing: border-box;

      &>div {
        padding: 0 40px !important;
      }
    }
  }

  &-intro {
    font-size: 24px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
  }
}