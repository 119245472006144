.card {
  flex: 0 0 auto;
  border: 1px solid #1D558F;
  background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/card-background-image.png') no-repeat top;
  background-size: cover;
  border-radius: 20px;
  box-sizing: border-box;

  overflow: hidden;

  &-title {
    display: flex;
    align-items: center;

    &-content {
      flex: 1;
    }
  }

  &-show-more-warp {
    height: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-show-more {
    width: 20px;
    height: 20px;
  }

  &-content {
    font-size: 24px;
    line-height: 40px;
    padding-top: 24px;
    word-break: break-all;

    height: 100%;
    margin-right: 9px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 139, 255, 0.4);
      border-radius: 4px;
    }

    &>div {
      padding: 0 60px;
    }
  }
}