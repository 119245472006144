.icon-type-count {
  width: 94px !important;
  &-header {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: HarmonyOS_Sans;
    font-weight: 400;
    color: #D9E7FF;
    line-height: 24px;
    margin-bottom: 3px;
    width: 94px !important;

    img {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }

  &-content {
    font-family: Oxanium;
    font-size: 32px;
    color: #fff;
    line-height: 38px;
    font-weight: Bolder;
    text-align: center;
    width: 94px !important;

    span {
      font-size: 20px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      opacity: 0.6;
      padding-left: 4px;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }
}