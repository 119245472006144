.date-picker-modal {
  &-modal {

    background-size: 100% 100%;
    min-width: 1032px;
    height: 718px;
    top: 152px !important;
    width: 520px;
    backdrop-filter: blur(6px);

    .ant-modal-content,
    .ant-modal-header {
      background: transparent;
      box-shadow: none;
      padding: 0;
    }
  }

  &-close {
    position: fixed;
    width: 56px;
    height: 56px;
    top: 125px;
    right: 56px;
    cursor: pointer;
    z-index: 10000;
  }
}