.effect-hot-broadcasting-cmg-modal {
  width: 1272px;
  padding: 0 60px;
  overflow: hidden;

  &-data {
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      img {
        width: 123px;
        height: 141px;
        margin-right: 13px;
        opacity: 0;
        animation: effect-icon-top-in 0.9s ease-in forwards;
      }
    }

    &-name {
      font-size: 28px;
      font-family: PingFang;
      color: #FFFFFF;
      line-height: 40px;
    }

    &-count {
      font-size: 56px;
      font-family: Oxanium;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 60px;
      background: linear-gradient(360deg, #6DC6FF 0%, #FFFFFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      span {
        font-size: 24px;
        font-family: HarmonyOS_Sans;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        margin-left: 8px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      width: 1272px;
    }

    &-item {
      display: flex;
      align-items: center;
      width: 272px;
      margin: 0 38px 56px 0;

      img {
        width: 72px;
        height: 72px;
        margin-right: 11px;
      }

      &-name {
        font-size: 24px;
        font-family: HarmonyOS_Sans;
        font-weight: 400;
        color: #D9E7FF;
        line-height: 24px;

        span {
          width: 23px;
          height: 40px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 40px;
          font-family: Oxanium;
          font-weight: 700;
          text-align: left;
          white-space: nowrap;
          line-height: 40px;
          margin-left: 12px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &-content {
        flex: 1;
        padding-top: 4px;
      }

      &-progress {
        width: 211px;
        margin-top: 8px;

        .ant-progress-inner {
          background: rgba(29, 141, 253, 0.1);
          border-radius: 100px;
          height: 4px;
          overflow: inherit;
          margin-top: 2px;
        }

        .ant-progress-bg {
          height: 4px !important;
        }
      }
    }

    &-extra {
      position: absolute;
      top: -60px;
      right: 62px;
      opacity: 0.5;
      font-size: 16px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: rgba(217, 231, 255, 0.6);
      line-height: 20px;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  &-card {
    height: 283px;
    box-sizing: border-box;
    border-radius: 20px;
    backdrop-filter: blur(6px);
    padding-bottom: 40px;

    .card-content {
      padding-top: 0;
      max-height: 100%;

      text-align: justify;
      text-align-last: left;

      &>div {
        padding: 40px 40px 0 40px;
      }
    }

    opacity: 0;
    animation: element-top-in 0.9s ease-in forwards;

    @keyframes element-top-in {
      0% {
        opacity: 0;
        margin-top: -100px;
      }

      100% {
        opacity: 1;
        margin-top: 0;
      }
    }
  }

  &-intro {
    font-size: 24px;
    font-family: Helvetica Bold;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    word-break: break-all;

    span {
      color: rgba(217, 231, 255, 0.3);
    }
  }
}