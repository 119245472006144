.cctv-data-item {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  height: 110px;
  box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.1);


  opacity: 0;
  animation: cctv-data-item-right-in 0.9s ease-in-out forwards;

  @keyframes cctv-data-item-right-in {
    0% {
      opacity: 0.3;
      right: -100px;
      margin-left: 100px;
    }

    100% {
      opacity: 1;
      right: 0;
      margin-left: 0;
    }
  }

  &-group {
    // display: flex;
    width: 280px;
    // overflow: hidden;
  }

  &-img {
    flex: 0 0 auto;
    width: 62px;
    height: 62px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    

    img {
      width: 48px;
      height: 26.8px;
    }
  }

  &-icon {
    width: 56px;
    height: 50px;
    margin-top: -6px;
  }

  &-data-item {
    text-align: center;
    font-family: Helvetica Bold;
    flex: 0 0 auto;

    &-key {
      font-size: 18px;
      font-family: HarmonyOS_Sans;
      font-weight: 400;
      color: #D9E7FF;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-value {
      font-size: 28px;
      font-family: Oxanium;
      font-weight: bold;
      color: #FFFFFF;
      height: 45px;
      line-height: 38px;
      // margin-bottom: 8px;
      text-align: center;
    }
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -25px;
  }

  .slick-dots li.slick-active button {
    background: linear-gradient(180deg, #24D8FF 0.7%, #1972FF 100%) !important;
  }

  .slick-dots li button {
    background: rgba(29, 141, 253, 0.9);
  }
}