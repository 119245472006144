.comment-detail-modal {
  padding: 0 95px;

  &-title {
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: 28px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 28px;
    // TODO 添加背景
    background: linear-gradient(180deg, #FFFFFF 0%, #90DEFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }

  &-container {
    padding: 0 30px;
    overflow-x: hidden;
  }
}