.badge {
  height: 26px;
  margin-left: 8px;
  padding: 0 10px;
  background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/badge-background-image.png') no-repeat top;
  font-size: 18px;
  border: 1px solid #F86B79;
  backdrop-filter: blur(6px);
  font-family: HarmonyOS_Sans;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 26px;
  border-radius: 20px;
}