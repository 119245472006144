.key-reports-other-themes-list-modal {
  padding: 0 80px;
  margin-right: 30px;
  font-size: 24px;
  color: #D9E7FF;
  height: 525px;
  box-sizing: border-box;

  height: 634px;
  overflow-y: auto;
  overflow-x: hidden;

  .card {
    color: #fff;
  }

  &-card {
    background: url(https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-media-theme-card.png) no-repeat top;
    background-size: 100% 100%;
    width: 100%;
    height: 108px;
    margin-bottom: 30px;

    padding: 20px 32px;
  }

  &-card:hover {
    background: #0080FF15;
  }

  &-card-title {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-family: HarmonyOS_Sans;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
    cursor: pointer;
  }

  &-theme {
    background: url('https://zbs-static.oss-cn-zhangjiakou.aliyuncs.com/yq-desktop/images/other-themes-background-image.png') no-repeat top;
    background-size: 100% 100%;
    margin-right: 16px;
    height: 32px;
    font-size: 20px;
    line-height: 32px;
    font-family: PingFangTC-Regular, PingFangTC;
    font-weight: 400;
    color: #FFFFFF;
    padding: 0 12px;
  }


  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 139, 255, 0.4);
    border-radius: 4px;
  }
}